<template>
    <div>
        <q-header class="bg-grey-9 text-grey-5 text-left">
            <q-toolbar>
                <q-btn flat round icon="menu" @click="toggleLeftDrawer" ></q-btn>
                <q-toolbar-title >
                    <!-- <q-avatar>
                        <q-icon name="home" ></q-icon>
                    </q-avatar> -->
                    {{center_info.center_name}}
                </q-toolbar-title>
                <user-top-menu></user-top-menu>
            </q-toolbar>
        </q-header>
        <q-drawer class="bg-grey-9 text-grey-5" show-if-above v-model="leftDrawerOpen" side="left" bordered :width="200" >
            <!-- drawer content -->
            <q-scroll-area class="fit text-left">
                <user-left-menu ></user-left-menu>
            </q-scroll-area>
        </q-drawer>
    </div>
</template>

<script>
import userTopMenu from './userTopMenu.vue'
import userLeftMenu from './userLeftMenu.vue'

export default {
    components : {
        userLeftMenu,
        userTopMenu,
    },
    data() {
        return {
            leftDrawerOpen : false,
        }
    },
    computed : {
        center_info() {
            return this.$store.state.center_info;
        },
    },
    methods : {
        toggleLeftDrawer() {
            this.leftDrawerOpen = !this.leftDrawerOpen;
        },
    },
    
    beforeMount() {
    },
}
</script>
